import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from "context/context"
import { isActiveLink } from 'utils'
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"
import Link from 'components/link'
import 'vendor/hamburgers.css'
import Button from 'components/button'

export default ({
	location
}) => {
    const { siteFields } = useStaticQuery(graphql`
        query {
            siteFields {
				pages {
					nodes {
						slug
						title
					}
				}
                menu (
					id: "TWVudTo0"
				) {
					menuItems {
						nodes {
							label
							title
							url
							childItems {
								nodes {
									label
									title
									url	
								}
							}
						}
					}
				}
            }
        }
	`)

	const { nodes: pages } = siteFields.pages

	const {
		options,
		isHeaderInverted,
		hasScrolled,
		checkInvertedHeaderOffsets,
	} = useContext(MainContext)

	const {
		logos,
		headerButtonLink,
	} = options

	useEffect(() => {
		setTimeout(() => {
			checkInvertedHeaderOffsets()
		},1000)

		if (typeof document !== 'undefined' && location && location.search && location.search.indexOf('scrollTo') !== -1) {
			setTimeout(() => {
				const idToScrollTo = location.search.split('scrollTo=')[1]

				const element = document.getElementById(idToScrollTo)

				if(element){
					scroll.scrollTo(element.offsetTop - 120)
				}else{
					console.log('no '+element)
				}
			}, 500)
		}
	}, [location])

	const [selectedChildItems, setSelectedChildItems] = useState([])
	const [hoveredMenuItemIndex, setHoveredMenuItemIndex] = useState(-1)

	let headerClassName = 'header'

	if(isHeaderInverted){
		headerClassName += ' inverted'
	} 

	if(hasScrolled){
		headerClassName += ' has-scrolled'
	} 

	const handleHover = (index, items = []) => {
		if(items.length){
			setSelectedChildItems(items)
			setHoveredMenuItemIndex(index)
		}else{
			setHoveredMenuItemIndex(-1)
		}
	}

	let headerContainerName = 'header-container'

	const LinkElement = (!location.pathname || location.pathname === '/') ? ScrollLink : Link

	const headerLinks = [
		{
			id: 'Columns',
			label: 'About'
		}, {
			id: 'Showcase',
			label: 'Stalls & Dates'
		}
	]

	return (
		<div 
			className={headerContainerName}
			onMouseLeave={() => handleHover(-1)}
		>
			<header className={headerClassName}>
				<div className="container">
					<div className="content">
						<Link
							to={'/'} 
							className={'logo'}
						>
							<img className="normal" src={logos.standard.sourceUrl} />
							<img className="inverted" src={logos.inverted.sourceUrl} />
						</Link>
						<ul className="scroll-links">
							{headerLinks.map(({
								id,
								label
							}) => (
									<li key={id}>
										<LinkElement
											activeClass="active"
											to={`${LinkElement === Link ? '/?scrollTo=' : ''}${id}`}
											spy={true}
											smooth={true}
											offset={-120}
											duration={500}
										>
											{label}
										</LinkElement>
									</li>
								))}
						</ul>
						{ headerButtonLink &&
							<Button 
								label={ headerButtonLink.title }
								url={ headerButtonLink.url }
								target="_blank"
							/>
						}
					</div>
				</div>
			</header>
			<div className={`header-sub-menu ${ hoveredMenuItemIndex !== -1 ? 'visible' : ''}`}>
				<ul>
					{ selectedChildItems.map((item, index) => {
						const {
							label,
							url
						} = item

						return (
							<li key={index}>
								<Link className={isActiveLink(location, url) ? 'active' : ''} to={url}>
									{ label }
								</Link>
							</li>
						)
					})}
				</ul>
			</div>
		</div>
	)
}

import React, { useContext } from 'react'
import { MainContext } from "context/context"
import Business from './modalTypes/business'

export default ({

}) => {
	const {
        modalData,
        closeModal,
        options,
        currentPage
    } = useContext(MainContext)

    const {
        type,
        data,
        isVisible
    } = modalData

    let component = null

    switch(type){
        case 'SHARE':
            if(currentPage && currentPage.submissionConfirmation){
                component = (
                    <>
                        <img src={require(`../images/red-heart.png`)} alt="Confirmation icon"/>
                        <h2>
                            { currentPage.submissionConfirmation.title }
                        </h2>
                        <p dangerouslySetInnerHTML={{ __html: currentPage.submissionConfirmation.body}} />
                    </>
                )
            }
            break;
        case 'BUSINESS':
            component = (
                <Business
                    business={data}
                />
            )
            break
    }

    return (
        <div 
            className={`modal ${ isVisible ? 'visible' : '' }`}
            onClick={closeModal}
        >
            <div 
                className={type}
                onClick={e => e.stopPropagation()}
            >
                { component }
            </div>
        </div>
    )
}
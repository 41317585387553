import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default ({
    children,
    to,
    dangerouslySetInnerHTML,
    className,
    onClick
}) => {
    const isExternal = to.startsWith('http')

    const commonProps = {
        className,
        onClick,
    }

    if(isExternal){
        return (
            <a 
                href={to}
                {...commonProps}
                target="_blank"
            >
                { children }
            </a>
        )
    }

    return (
        <AniLink 
            cover
            duration={0.7}
            direction="left" 
            bg={'#0061af'}
            to={to.startsWith('/') ? to : '/'+to} 
            dangerouslySetInnerHTML={dangerouslySetInnerHTML} 
            className={className}
            onClick={onClick ? onClick : undefined}
        >
            { children }
        </AniLink>
    )
}
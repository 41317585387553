import React, { useState, useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const MainContext = React.createContext()

const Provider = ({
	children
}) => {
	const shareRef = useRef(null)

	const componentRefs = useRef([])

	const [modalData, setModalData] = useState(false)
	const [isHeaderInverted, setIsHeaderInverted] = useState(false)
	const [hasScrolled, setHasScrolled] = useState(false)
	const [currentPage, setCurrentPage] = useState({}) // For non-flexible-content globals
	const invertedHeaderSections = useRef([])

	const isHeaderInvertedRef = useRef(isHeaderInverted)
	isHeaderInvertedRef.current = isHeaderInverted

	const hasScrolledRef = useRef(hasScrolled)
	hasScrolledRef.current = hasScrolled

	const _window = typeof window !== 'undefined' ? window : null
	
	const addInvertedHeaderSection = ref => {
		const { current } = invertedHeaderSections

		if(current.indexOf(ref) === -1){
			current.push(ref)
		}

		invertedHeaderSections.current = current

		checkInvertedHeaderOffsets()
	}

	useEffect(() => {
		let scrollHandler = null

		if(_window){
			scrollHandler = _window.addEventListener('scroll', checkInvertedHeaderOffsets)
		}

		return () => {
			if(scrollHandler){
				scrollHandler.remove()
			}
		}
	}, [_window])

	const checkInvertedHeaderOffsets = () => {
		const invertRefs = invertedHeaderSections.current
		let _isHeaderInverted = false

		if(_window){
			const scrollOffset = _window.pageYOffset

			if(scrollOffset > 10 && !hasScrolledRef.current){
				setHasScrolled(true)
			}else if(scrollOffset <= 10 && hasScrolledRef.current){
				setHasScrolled(false)
			}
		}

		invertRefs.forEach(ref => {
			const offsets = ref.getBoundingClientRect()

			const isOverSection = offsets.top < 120 && (offsets.top + offsets.height) > 120

			if(isOverSection){
				_isHeaderInverted = true
			}
		})

		if(_isHeaderInverted !== isHeaderInvertedRef){
			setIsHeaderInverted(_isHeaderInverted)
		}
	}

    const openModal = props => {
        setModalData({
            ...props,
            isVisible: true
        })
	}
	
	const closeModal = () => {
        setModalData({
            ...modalData,
            isVisible: false
        })
    }

	const optionsQuery = useStaticQuery(graphql`
		query optionsQuery {
			siteFields {
				options {
					options {
						headerButtonLink {
							title
							url
						}
						logos {
							standard {
								sourceUrl
							}
							inverted {
								sourceUrl
							}
						}
						socialLinks {
							type
							url
						}
						footerLogos {
							image {
								sourceUrl
							}
							url
						}
					}
				}
			}
		}
	`)
	
	const { options } = optionsQuery.siteFields.options

	return (
		<MainContext.Provider 
			value={{
				options,
				openModal,
				closeModal,
				modalData,
				addInvertedHeaderSection,
				checkInvertedHeaderOffsets,
				isHeaderInverted,
				hasScrolled,
				shareRef,
				componentRefs,
				setCurrentPage,
				currentPage
			}}
		>
			{ children }
		</MainContext.Provider>
	)
};

export default ({ element }) => (
	<Provider>
		{element}
	</Provider>
);
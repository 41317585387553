import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from "context/context"

export default ({

}) => {
	const {
		options: {
            logos
        },
    } = useContext(MainContext)

    const [isMounted, setIsMounted] = useState(false)
    const [isHidden, setIsHidden] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setIsMounted(true)
        },100)

        setTimeout(() => {
            setIsHidden(true)
        },2100)
    },[])
    
    return (
        <div className={`page-loader ${ isMounted ? 'mounted' : '' } ${ isHidden ? 'hidden' : '' }`}>
            <img src={logos.standard.sourceUrl} alt="Coburg Night Market" />
        </div>
    )
}